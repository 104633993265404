import React from 'react'
import ReactDOM from 'react-dom'
import Modal from './'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const renderFullScreenModal = (options) => {
  const containerId = options.id + '-container'

  const container = document.createElement('div')
  container.setAttribute('id', containerId)

  document.body.appendChild(container)

  const ref = React.createRef()

  let modalProps = options.modalProps || {}
  modalProps.container = container
  modalProps.classNames = {}
  modalProps.classNames.modal =
    'w-full max-w-full m-0 p-0 shadow-none h-full bg-white dark:bg-gray-900'
  modalProps.classNames.closeButton = 'focus:outline-none mr-4 mt-4 lg:mr-8'
  modalProps.closeIcon = (
    <span
      className={
        'flex items-center justify-center border border-gray-200 dark:border-gray-700 rounded-full w-12 h-12'
      }
    >
      <FontAwesomeIcon
        className={'text-lg text-gray-600 dark:text-gray-200'}
        icon={['fal', 'times']}
      />
    </span>
  )

  ReactDOM.render(
    <Modal
      {...options}
      handleClose={() => {
        container.remove()
      }}
      modalProps={modalProps}
      ref={ref}
    />,
    document.getElementById(containerId),
  )

  return ref
}

export const renderBasicModal = (options) => {
  const containerId = options.id + '-container'

  const container = document.createElement('div')
  container.setAttribute('id', containerId)

  document.body.appendChild(container)

  const ref = React.createRef()

  let modalProps = options.modalProps || {}
  modalProps.container = container
  modalProps.classNames = {}
  modalProps.center = true
  modalProps.classNames.modal = 'shadow-none bg-white dark:bg-gray-900 rounded-2xl'
  modalProps.classNames.closeButton = 'focus:outline-none'
  modalProps.closeIcon = (
    <span
      className={
        'flex items-center justify-center border border-gray-100 dark:border-gray-700 rounded-full w-6 h-6'
      }
    >
      <FontAwesomeIcon
        className={'text-sm text-gray-600 dark:text-gray-200'}
        icon={['fal', 'times']}
      />
    </span>
  )

  ReactDOM.render(
    <Modal
      {...options}
      handleClose={() => {
        container.remove()
      }}
      modalProps={modalProps}
      ref={ref}
    />,
    document.getElementById(containerId),
  )

  return ref
}
