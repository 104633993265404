import React, { useState, useImperativeHandle } from 'react'
import { Modal as ReactModal } from 'react-responsive-modal'

const Modal = React.forwardRef((props, ref) => {
  const { id, html, handleClose, modalProps } = props

  const [isShow, setShow] = useState(true)

  const delayCloseHandler = 500 // 0.5 seconds

  const close = () => {
    setShow(false)

    const timeOutID = setTimeout(() => {
      handleClose()
    }, delayCloseHandler)

    return () => clearTimeout(timeOutID)
  }

  useImperativeHandle(ref, () => ({
    close: close,
  }))

  return (
    <div id={id} ref={ref}>
      <ReactModal {...modalProps} open={isShow} onClose={close}>
        {html}
      </ReactModal>
    </div>
  )
})

Modal.displayName = 'Modal'

export default Modal
